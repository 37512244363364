<template>
  <div class="related-products__product">
    <div class="related-products__product--image">
      <div class="image" :style="image"></div>
    </div>
    <ul class="related-products__product--props">
      <li v-html="description"></li>
      <li>Prix unitaire TTC : {{ (price).toFixed(2) }} € / {{ serviceUnit }}</li>
      <li>Prix total TTC : {{ (price).toFixed(2) }} €</li>
    </ul>
    <div v-if="loader" class="loader-wrapper">
      <div class="loader">
        <v-progress-circular
          :size="40"
          :width="2"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductInfo",
  props: {
    loader: {
      type: Boolean,
    },
    image: {
      type: Object,
    },
    description: {
      type: String,
      required: true,
    },
    pricePerUnit: {
      type: Number,
      required: true,
    },
    priceTotal: {
      type: Number,
      required: true,
    },
    serviceUnit: {
      type: String,
      required: true,
    },
  },
  computed: {
    price() {
      return this.pricePerUnit ?? 0;
    },
  },
};
</script>

<style>
</style>